.nnua{
    display: flex;
    flex-direction: column;
    max-width: 1180px;
    margin: 160px auto 0;
    &_img{
        width: 100%;
        max-width: 1177px;
        height: 1117px;
        margin: 48px 0 0 0;
    }
}

.help-f-of-ua{
    position: relative;
    display: flex;
    flex-direction: column;
    max-width:1180px ;
    margin: 160px auto 0;
   

    &_title{
        display: flex;
        flex-direction: column;
        max-width: 716px;
        &>span:last-child{
            margin-top: 16px;
            font-size: 24px;
        }
    }
    &>span{
        font-family: Montserrat800;
        font-size: 48px;
        line-height: 58.5px;
        color: #ffffff;
        margin-top: 80px;
        text-transform: uppercase;
    }
}

.modail-need-a-of-f{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    border-radius:50px; 
    margin: 64px 0;
    padding: 100px 72px;
    background: linear-gradient(90.3deg, #fffcfc87 6.36%, #ffffffdb 101.69%);
    box-shadow: 0px 0px 18px #00000021;

    
    &::before{
        content:"";
        position:absolute;
        width: 100%;
        height: 100%;
        top: -4px;
        left:-4px;
        right:0;
        bottom:0;
        border-radius:56px; 
        padding:4px; 
        background:linear-gradient(90deg,#fffcfc87,#ffffff); 
        -webkit-mask: 
           linear-gradient(#ffffff 0 0) content-box, 
           linear-gradient(#ffffff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
    }

    &_title>span{
        display: flex;
        max-width: 781px;
        text-align: end;
    }

    &_table{
        width:100% ;
        max-width: 570px;
        text-align: end;
    }

    &_backgound{
        

        &>img{
            overflow: hidden;
            // width: 128%;
            filter: blur(90px);
        }
    }

    &_img{
        position: absolute;
        bottom: -9px;
        left: -23px;
    }

    
}



