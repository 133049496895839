.bg-footer{
    background-color:#0057B8;
    padding: 60px 0;
}

.footer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1180px;
    min-height: 323px;
    margin: 0 auto;
    &_case{
        display: flex;
        justify-content: space-between;
    }

    &>span{
        font-family: Montserrat800;
        font-size: 28px;
        line-height: 34px;
        color: #ffffff;
        text-transform: uppercase;
    }

    &_case{
        display: flex;
        align-items: flex-end;
    }
}

.footer-marck{
    display: flex;
    align-items: flex-end;
    position: relative;
    max-width: 400px;
    bottom: 0;
    font-family: Montserrat500;
    height: 26px;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    text-transform: uppercase;
    &>img{
        position: relative;
        top: -8px;
        left: 4px;
    }    
}

.nav-footer{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 651px;
    &>button{
        height: 26px;
        font-family: Montserrat600;
        height: 26px;
        font-size: 21px;
        line-height: 25px;
        color: #ffffff;
        border: transparent;
        background: transparent;
        cursor: pointer;
        &:hover{
            color:#FFDD00;
        }
    }
}

