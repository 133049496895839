.look-parner{
    display: flex;
    flex-direction: column;
    
    justify-content: space-between;
    position: relative;
    max-width: 1180px;
    margin: 80px auto 0;
    &_title{
        max-width: 670px;
        text-align: start;
    }

    &_modail{
        display: flex;
        flex-direction: column;
        margin: 80px 0 0 0;
        padding: 40px 103px;
        background: #F4FAFF;
        border-radius: 32px;
    }
}

.case-conten-lp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;

    

    &_text{
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 601px;

        &>span{
            display: block;
        }

        &>span:first-child{
            font-family: Montserrat700;
            font-size: 32px;
            line-height: 39px;
            margin: 16px 0;
        }

        &>span:last-child{
            font-family: Montserrat400;
            font-size: 23px;
            line-height: 28px;
        }
    }
    &:last-child{
        margin: 0;
    }

    &_img {
        border-radius: 30px;
    }
}