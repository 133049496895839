@font-face {
  font-family: Montserrat400;
  src: url(../scss/font/Montserrat-Regular.ttf);
}

@font-face {
  font-family: Montserrat500;
  src: url(../scss/font/Montserrat-Medium.ttf);
}

@font-face {
  font-family: Montserrat600;
  src: url(../scss/font/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: Montserrat700;
  src: url(../scss/font/Montserrat-Bold.ttf);
}

@font-face {
  font-family: Montserrat800;
  src: url(../scss/font/Montserrat-ExtraBold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #bebebe

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.web-site{
  position: relative;
  margin: 0 auto; 
  z-index: 1;
}

// .bg-top{
//   background: url(../img/bgtop.png) no-repeat center center;
// }

.top-backgraund{
  position: absolute;
  display: flex;
  justify-content: center;
  top: -44px;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  filter: blur(30px);
  user-select: none;
  
  &>div{
    position: relative;
  }
}

.midle-backgraund{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  filter: blur(70px);
  user-select: none;
  
  &>div{
    position: relative;
    top: -10px;
  }
}

.bottun-backgraund{
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 1150px;
  z-index: -1;
  overflow: hidden;
  filter: blur(70px);
  user-select: none;
  
  &>div{
    position: relative;
    top: 260px;
    left: -160px;
    &>img{
      width: 130%;
    }
  }
}

.title{
  font-family: Montserrat800;
  font-size: 48px;
  // line-height: 58.51px;
  text-transform: uppercase;
}

.title-big{
  font-family: Montserrat800;
  font-size: 90px;
  color: #0524C9CC;
  line-height: 110px;
  text-transform: uppercase;
}

.sub-title{
  font-family: Montserrat500;
  font-size: 23px;
  color: #228AFF;
  // line-height: 28px;
  text-transform: uppercase;
}

.count{
  font-family:Montserrat800 ;
  font-size: 68px;
  color:#FFDD00;
}

.list{
  ul{
      li{
          font-family:Montserrat400 ;
          font-size: 23px;
          color:#000000;
          padding: 23px 0 17px 0;
          list-style-type: none;
      }
      li::marker{
          content: url(../img/marker.svg);
      }
      
      li:last-child{
          border-bottom: 1px solid transparent;
      }
  }
}

.list__border{
  &>ul>li{
    border-bottom: 1px solid #0000004D;
  }
}

.boolu{
  position: absolute;
  bottom: 36px;
  left: 46px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #FFDD0080;
}

.boolb{
  position: absolute;
  top: 36px;
  right:  46px;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #228AFF80;
}


.modail-backgound{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.05;
  z-index: -2;
  border-radius: 54px;
  overflow: hidden;
  background-image: url(../img/Rectangle.png);
  background-size: covers;
  backdrop-filter: blur(3px);
}

.loade{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #FFDD00;
  
  &>img{
    display: block;
    position: relative;
    top: -50px;
    width: 160px;
    margin: 0 auto;
  }

  &_bar{
    top: 100px;
    position: relative;
    display: flex;
    width: 200px;
    height: 200px;
    border:10px solid #0524C9CC;
    border-radius: 100%;
    
    margin: 0 auto;
    &>div{
      width: 180px;
      height: 180px;
      position: absolute;
      top: -12px;
      left: -17px;
      border: 30px solid transparent;
      border-bottom: 25px solid #FFDD00;
      border-radius: 100%;
      animation: l 1s linear infinite;
    }
  }

  

  &__des{
    display: none;
  }
}

@keyframes l {
  0%{
    transform: rotate(0deg);
  }

  100%{
    transform: rotate(360deg);
  }
}