.how-its-worck{
    display: flex;
    flex-direction: column;
    max-width: 1180px;
    margin: 266px auto 0;
    &_title{
        display: flex;
        flex-direction: column;
        margin-bottom: 48px;
    }

    &_info{
        
    }
}

.hwch-info{
    display: flex;
    margin-bottom: 32px;
    &_info{
        display: flex;
        flex-direction: column;
        max-width: 760px;
        
    }
    
    &_counter{
        width: 100%;
        text-align: end;
        max-width: 570px;
        width: 80%;
        border-bottom: 2px solid #FFDD00;
    }
}