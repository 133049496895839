.of-charity-hero{
    position: relative;
    max-width: 1180px;
    margin: 157px auto 0;
    &_title{
        margin-bottom: 48px;
    }

    &_info{
        margin-bottom: 32px;
    }

    &_info>img{
        margin-right: 48px;
    }

    &_img{
        position: absolute;
        top: 70px;
        right: 19px;
        border-radius: 90px;
    }
}

.case-och{
    display: flex;
    &_info-case{
        display: flex;
        flex-direction: column;
        max-width: 585px;
       
    }
}

.title-och{
    font-family: Montserrat700;
    font-size: 32px;
    text-transform: uppercase;
}

.info-och{
    font-family: Montserrat400;
    font-size: 23px;
    
}